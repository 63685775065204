/* General styles */
.termsAndConditions {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.serviceLeadingSection {
  width: 80%;
  margin: 15px 0;
}

.sn {
  font-size: 18px;
  padding-right: 5px;
}

.st {
  font-size: 24px;
}

.spl {
  margin: 15px 0 !important;
  text-align: justify;
}

.serviceInfoContainer {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
}

.serviceLead {
  font-weight: 700 !important;
  margin: 15px !important;
  margin-bottom: 5px !important;
}

.serviceDetails {
  margin-left: 15px !important;
  text-align: justify;
}

.secionLine {
  height: 40px;
  width: 5px;
  position: absolute;
  top: 5px;
  opacity: .8;
}

/* Font Colours */
.lightGreen {
  color: rgb(85, 189, 134);
}

.blue {
  color: #2998ff;
}

.orange {
  color: #ff7730;
}

.purple {
  color: #2a0073;
}

/* Line Colours */
.lineColorGreen {
  background-image: linear-gradient(to right bottom, rgb(85, 189, 134), rgb(11, 103, 92));
}

.lineColorBlue {
  background-image: linear-gradient(to right bottom, #2998ff, #5643fa);
}

.lineColorOrange {
  background-image: linear-gradient(to right bottom, #ffb900, #ff7730);
}

.lineColorPurple {
  background-image: linear-gradient(to bottom right, #b25f88, #2a0073);
}

.closeTerms {
  border-bottom: 1px solid black;
  margin: 15px;
  padding-bottom: 5px;
}

.bulletPoint {
  margin-right: 10px;
  top: 5px;
  position: relative;
  align-self: start;
  font-weight: 900;
}

.listNum {
  margin-right: 10px;
  top: 5px;
  position: relative;
  align-self: start;
}