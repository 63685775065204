@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');

html,
body,
#root,
.app,
.content{
  height: 100%;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
}

.app{
  display: flex;
  position: relative;
}

::-webkit-scrollbar{
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track{
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb{
  background: #888;
}

/* Handle on Hover*/
::-webkit-scrollbar-track:hover{
  background: #555;
}

.innerBoxContainer {
  width: 100%;
  max-width: 1100px;
  padding: 5vh 0;
}

.innerTitleContainer{
  padding: 5vh 0;
}

.subTitleContainer{
  padding: 2vh 0;
  font-weight: 800;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
